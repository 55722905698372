import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Menu from "./menu"
import PageHeader from "./page-header"
import Footer from "./footer"
import { Helmet } from "react-helmet"

const Layout = props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          name
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <title>
          {props.pageTitle} - {data.site.siteMetadata.name || ""}
        </title>
        <meta name="description" content={props.pageDescription || ""} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@GauntletUofC" />
        <meta property="og:url" content="https://covid19.thegauntlet.ca" />
        <meta
          property="og:description"
          content="Tracking The Spread of Coronavirus in Canada"
        />
        <meta property="og:title" content="COVID-19 | The Gauntlet" />
        <meta
          property="og:image"
          content="https://camo.githubusercontent.com/8ecec2a7d7d5f47f6ccd8ec40834f5dec1585f81/68747470733a2f2f6d6b662d7075626c69632e73332e63612d63656e7472616c2d312e616d617a6f6e6177732e636f6d2f6761747362792d737461727465722d63616e6164612d70616e64656d69632d76322e706e67"
        />
        <meta
          property="og:image:alt"
          content="Tracking The Spread of Coronavirus in Canada | The Gauntlet"
        />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="314" />
      </Helmet>

      <div id="site-wrapper">
        <Menu
          currentPage={props.pageTitle}
          name={data.site.siteMetadata.name}
        />

        <div id="page-content">
          <div className="" id="content-wrapper">
            <PageHeader
              title={
                props.pageTitle.indexOf("Not Found") === -1
                  ? "Confirmed Cases of COVID-19 in Canada"
                  : props.pageTitle
              }
            />
          </div>
          {props.children}
        </div>

        <footer>
          <div className="container">
            <div className="row">
              <div className="column">
                <Footer name={data.site.siteMetadata.name} />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default Layout
